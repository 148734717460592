import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Pusher from "pusher-js";
function Sectionspread({ islayout }) {
  let { id } = useParams();
  const [isSpread, setIsSpread] = useState({});

  useEffect(() => {
    const pusher = new Pusher("44f2900f2b03ee38357a", {
      cluster: "ap1",
      appId: 1158859,
      encrypted: true,
    });
    const channel = pusher.subscribe("quotes");

    channel.bind("update", function (e) {
      const a = e.find((ab) => ab.s === `${id.toUpperCase()}`);
      setIsSpread(a);
    });
  }, [id]);
  const spreadPrice = (data) => {
    let spread = data.a - data.b;
    let a = Math.pow(10, data.d - 1);
    let final = spread * a;
    return (
      <div className="ml-1 text-right" style={{ width: 32 }}>
        {final.toFixed(1)}
      </div>
    );
  };
  return (
    <div>
      {" "}
      <div
        className={`${
          islayout === "dashboard" ? "bg-white" : "bg-dark-gray"
        } p-4`}
        style={{ borderRadius: 10 }}
      >
        <h4 className={islayout !== "dashboard" && "text-white"}>
          Spread on Iuxmarkets
        </h4>
        <div
          className={` py-4 fs-24 d-flex ${
            islayout !== "dashboard" && "text-white"
          }`}
        >
          {isSpread && spreadPrice(isSpread)}&nbsp;&nbsp;PIPs
        </div>
      </div>
    </div>
  );
}

export default Sectionspread;
