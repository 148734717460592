import React, { useEffect, useState } from "react";
import { CaretUpOutlined, RiseOutlined } from "@ant-design/icons";
import Pusher from "pusher-js";
import { Link, Switch, Route } from "react-router-dom";

import AllSymbol from "../symbol/price";

import { Divider } from "antd";
import styled from "styled-components";
import TextWithCoverButton from "../../components/text-with-cover/TextWithCoverButton";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import {
  styleHeaderDashboard,
  styleBitAsk,
  styleSpreadDashboard,
  styleBuyDashboard,
  styleSellDashboard,
} from "../symbol/style";
const locale = require("react-redux-i18n").I18n;
const TopMenu = styled.div`
  background: rgb(255, 255, 255);
  margin-top: 0px;
  border-radius: 20px;
`;
const MenuItem = styled.div`
  color: ${(props) => props.color};
  white-space: pre;
  padding: 0.8rem 1rem;
  font-weight: ${(props) => props.fontWeight};
  &:after {
    display: block;
    content: "";
    margin-top: 0.8rem;
    border-bottom: 4px solid #1cb894;
    transform: scaleX(${(props) => (props.borderBottom ? 1 : 0)});
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`;
const SearchResault = styled.div`
  position: absolute;
  background: #fff;
  width: 138px;
  z-index: 2;
  max-height: 50vh;
  overflow: auto;
`;

const SearchInput = styled.input`
  outline: none;
  background: #151e20;
  border: 1px solid #454a4c;
  border-radius: 4px;
  padding: 0 12px;
  color: #fff;
  height: 40px;
`;

const Symbols = (props) => {
  const pathName = window.location.pathname;
  const [data, setData] = useState(null);
  const [currencies, setCurrencies] = useState(null);
  const [crypto, setCrrypto] = useState(null);
  const [marketIndex, setMarketIndex] = useState(null);
  const [stocks, setStocks] = useState(null);
  const [isIdex, setIndex] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [resault, setResault] = useState([]);
  const [searchResault, setSearchResault] = useState([]);
  const [search, setSearch] = useState([]);
  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/wallet/spread`,
      text: locale.t("All"),
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/wallet/spread/currency`,
      text: locale.t("Currency"),
    },
    {
      id: 2,
      link: `/${localStorage.getItem("locale")}/wallet/spread/crypto`,
      text: locale.t("Crypto"),
    },
    {
      id: 3,
      link: `/${localStorage.getItem("locale")}/wallet/spread/index`,
      text: locale.t("INDEX"),
    },
    {
      id: 4,
      link: `/${localStorage.getItem("locale")}/wallet/spread/stocks`,
      text: locale.t("STOCKS"),
    },
  ];
  const currency = [
    "AUDUSD",
    "CADJPY",
    "EURGBP",
    "EURJPY",
    "EURUSD",
    "GBPUSD",
    "NZDUSD",
    "USDCAD",
    "USDCHF",
    "USDJPY",
    "AUDCAD",
    "AUDCHF",
    "AUDJPY",
    "AUDNZD",
    "CADCHF",
    "CHFJPY",
    "CHFSGD",
    "ERUAUD",
    "EURCAD",
    "EURCHF",
    "EURDKK",
    "EURHKD",
    "EURHUF",
    "EURMXN",
    "EURNOK",
    "EURNZD",
    "EURPLN",
    "EURSEK",
    "EURTRY",
    "ERUZAR",
    "GBPAUD",
    "GBPCAD",
    "GBPCHF",
    "GBPDKK",
    "GBPJPY",
    "GBPNOK",
    "GBPNZD",
    "GBPSEK",
    "GBPTRY",
    "NZDCAD",
    "NZDCHF",
    "NZDJPY",
    "NZDSGD",
    "USDCNH",
    "USDZCK",
    "USDDKK",
    "USDHKD",
    "USDHUF",
    "USDMXN",
    "USDNOK",
    "USDPLN",
    "USDRUB",
    "USDSEK",
    "USDSGD",
    "USDTRY",
    "USDZAR",
    "UKOUSD",
    "USOUSD",
    "XAGEUR",
    "XAGUSD",
    "XAUEUR",
    "XAUUSD",
    "XNGUSD",
    "XPDUSD",
    "XPTUSD",
    "EURAUD",
    "USDCNY",
    "EURSGD",
    "USDTHB",
    "GBPSGD",
    "CADSGD",
    "AUDSGD",
    "SGDJPY",
    "XAUGBP",
  ];
  const cryptoCurrency = [
    "BTCEUR",
    "BTCGBP",
    "BTCUSD",
    "DSHUSD",
    "ETHEUR",
    "ETHGBP",
    "ETHUSD",
    "LTCUSD",
    "XRPUSD",
    "DOTUSD",
    "BCHUSD",
    "BNBUSD",
    "XMRUSD",
    "ADAUSD",
    "ADAUSD",
    "UNIUSD",
    "XTZUSD",
    "DOTUSD",
    "SOLUSD",
    "BATUSD",
    "TRXUSD",
    "XMRUSD",
    "ATMUSD",
    "AVAUSD",
  ];

  const indexs = [
    "100GBP",
    "AUS200",
    "D30EUR",
    "DJI30",
    "DXYUSD",
    "E35EUR",
    "E50EUR",
    "F40EUR",
    "JPN225",
    "NASUSD",
    "SP500",
    "USOIL",
    "HK50",
    "DE30",
    "US30",
    "S&P500",
    "UK100",
    "DXY",
    "FR40",
    "STOXX50",
    "USTEC",
  ];

  const marketStock = [
    "AAPL",
    "ADBE",
    "ALIBABA",
    "AMZN",
    "AMD",
    "BA",
    "BABA",
    "BAC",
    "CHTR",
    "CMCSA",
    "CME",
    "COST",
    "CSCO",
    "CSX",
    "CVS",
    "Citi",
    "EA",
    "EBAY",
    "EQIX",
    "Ford",
    "GILD",
    "GOOGL",
    "HD",
    "IBM",
    "INTC",
    "ISRG",
    "JNJ",
    "JPM",
    "KO",
    "LIN",
    "LLY",
    "LMT",
    "MA",
    "MCD",
    "MDLZ",
    "MMM",
    "MRK",
    "MS",
    "MSFT",
    "NFLX",
    "NKE",
    "NVDA",
    "ORCL",
    "PEP",
    "PFE",
    "PG",
    "PYPL",
    "SBUX",
    "TMO",
    "TMUS",
    "TSLA",
    "UNH",
    "VRTX",
    "Visa",
    "WMT",
    "XOM",
    "META",
  ];
  const marketALL = [
    { name: "AAPL", display: "AAPL" },
    { name: "ADBE", display: "ADBE" },
    { name: "ALIBABA", display: "ALIBABA" },
    { name: "AMZN", display: "AMZN" },
    { name: "AMD", display: "AMD" },
    { name: "AMT", display: "AMT" },
    { name: "ATVI", display: "ATVI" },
    { name: "AVGO", display: "AVGO" },
    { name: "BA", display: "BA" },
    { name: "BABA", display: "BABA" },
    { name: "BAC", display: "BAC" },
    { name: "BIIB", display: "BIIB" },
    { name: "BMY", display: "BMY" },
    { name: "CHTR", display: "CHTR" },
    { name: "CMCSA", display: "CMCSA" },
    { name: "CME", display: "CME" },
    { name: "COST", display: "COST" },
    { name: "CSCO", display: "CSCO" },
    { name: "CSX", display: "CSX" },
    { name: "CVS", display: "CVS" },
    { name: "Citi", display: "Citi" },
    { name: "EA", display: "EA" },
    { name: "EBAY", display: "EBAY" },
    { name: "EQIX", display: "EQIX" },
    { name: "Ford", display: "F" },
    { name: "GILD", display: "GILD" },
    { name: "GOOGL", display: "GOOGL" },
    { name: "HD", display: "HD" },
    { name: "IBM", display: "IBM" },
    { name: "INTC", display: "INTC" },
    { name: "ISRG", display: "ISRG" },
    { name: "JNJ", display: "JNJ" },
    { name: "JPM", display: "JPM" },
    { name: "KO", display: "KO" },
    { name: "LIN", display: "LIN" },
    { name: "LLY", display: "LLY" },
    { name: "LMT", display: "LMT" },
    { name: "MA", display: "MA" },
    { name: "MCD", display: "MCD" },
    { name: "MDLZ", display: "MDLZ" },
    { name: "MMM", display: "MMM" },
    { name: "MRK", display: "MRK" },
    { name: "MS", display: "MS" },
    { name: "MSFT", display: "MSFT" },
    { name: "NFLX", display: "NFLX" },
    { name: "NKE", display: "NKE" },
    { name: "NVDA", display: "NVDA" },
    { name: "ORCL", display: "ORCL" },
    { name: "PEP", display: "PEP" },
    { name: "PFE", display: "PFE" },
    { name: "PG", display: "PG" },
    { name: "PM", display: "PM" },
    { name: "PYPL", display: "PYPL" },
    { name: "REGN", display: "REGN" },
    { name: "SBUX", display: "SBUX" },
    { name: "T", display: "T" },
    { name: "TMO", display: "TMO" },
    { name: "TMUS", display: "TMUS" },
    { name: "TSLA", display: "TSLA" },
    { name: "UPS", display: "UPS" },
    { name: "VZ", display: "VZ" },
    { name: "UNH", display: "UNH" },
    { name: "VRTX", display: "VRTX" },
    { name: "Visa", display: "V" },
    { name: "WMT", display: "WMT" },
    { name: "XOM", display: "XOM" },
    { name: "META", display: "META" },
    { name: "100GBP", display: "100GBP" },
    { name: "AUS200", display: "AUS200" },
    { name: "D30EUR", display: "D30EUR" },
    { name: "DJI30", display: "DJI30" },
    { name: "DXYUSD", display: "DXYUSD" },
    { name: "E35EUR", display: "E35EUR" },
    { name: "E50EUR", display: "E50EUR" },
    { name: "F40EUR", display: "F40EUR" },
    { name: "JPN225", display: "JPN225" },
    { name: "NASUSD", display: "NASUSD" },
    { name: "SP500", display: "SP500" },
    { name: "USOIL", display: "USOIL" },
    { name: "UKOIL", display: "UKOIL" },
    { name: "HK50", display: "HK50" },
    { name: "DE30", display: "DE30" },
    { name: "US30", display: "US30" },
    { name: "S&P500", display: "S&P500" },
    { name: "UK100", display: "UK100" },
    { name: "DXY", display: "DXY" },
    { name: "FR40", display: "FR40" },
    { name: "STOXX50", display: "STOXX50" },
    { name: "USTEC", display: "USTEC" },
    { name: "BTCEUR", display: "BTCEUR" },
    { name: "BTCGBP", display: "BTCGBP" },
    { name: "BTCUSD", display: "BTCUSD" },
    { name: "DSHUSD", display: "DSHUSD" },
    { name: "ETHEUR", display: "ETHEUR" },
    { name: "ETHGBP", display: "ETHGBP" },
    { name: "ETHUSD", display: "ETHUSD" },
    { name: "LTCUSD", display: "LTCUSD" },
    { name: "XRPUSD", display: "XRPUSD" },
    { name: "DOTUSD", display: "DOTUSD" },
    { name: "BCHUSD", display: "BCHUSD" },
    { name: "BNBUSD", display: "BNBUSD" },
    { name: "XMRUSD", display: "XMRUSD" },
    { name: "ADAUSD", display: "ADAUSD" },
    { name: "ADAUSD", display: "ADAUSD" },
    { name: "UNIUSD", display: "UNIUSD" },
    { name: "XTZUSD", display: "XTZUSD" },
    { name: "DOTUSD", display: "DOTUSD" },
    { name: "SOLUSD", display: "SOLUSD" },
    { name: "BATUSD", display: "BATUSD" },
    { name: "TRXUSD", display: "TRXUSD" },
    { name: "XMRUSD", display: "XMRUSD" },
    { name: "ATMUSD", display: "ATMUSD" },
    { name: "AVAUSD", display: "AVAUSD" },
    { name: "AUDUSD", display: "AUDUSD" },
    { name: "CADJPY", display: "CADJPY" },
    { name: "EURGBP", display: "EURGBP" },
    { name: "EURJPY", display: "EURJPY" },
    { name: "EURUSD", display: "EURUSD" },
    { name: "GBPUSD", display: "GBPUSD" },
    { name: "NZDUSD", display: "NZDUSD" },
    { name: "USDCAD", display: "USDCAD" },
    { name: "USDCHF", display: "USDCHF" },
    { name: "USDJPY", display: "USDJPY" },
    { name: "AUDCAD", display: "AUDCAD" },
    { name: "AUDCHF", display: "AUDCHF" },
    { name: "AUDJPY", display: "AUDJPY" },
    { name: "AUDNZD", display: "AUDNZD" },
    { name: "CADCHF", display: "CADCHF" },
    { name: "CHFJPY", display: "CHFJPY" },
    { name: "CHFSGD", display: "CHFSGD" },
    { name: "ERUAUD", display: "ERUAUD" },
    { name: "EURCAD", display: "EURCAD" },
    { name: "EURCHF", display: "EURCHF" },
    { name: "EURDKK", display: "EURDKK" },
    { name: "EURHKD", display: "EURHKD" },
    { name: "EURHUF", display: "EURHUF" },
    { name: "EURMXN", display: "EURMXN" },
    { name: "EURNOK", display: "EURNOK" },
    { name: "EURNZD", display: "EURNZD" },
    { name: "EURPLN", display: "EURPLN" },
    { name: "EURSEK", display: "EURSEK" },
    { name: "EURTRY", display: "EURTRY" },
    { name: "ERUZAR", display: "ERUZAR" },
    { name: "GBPAUD", display: "GBPAUD" },
    { name: "GBPCAD", display: "GBPCAD" },
    { name: "GBPCHF", display: "GBPCHF" },
    { name: "GBPDKK", display: "GBPDKK" },
    { name: "GBPJPY", display: "GBPJPY" },
    { name: "GBPNOK", display: "GBPNOK" },
    { name: "GBPNZD", display: "GBPNZD" },
    { name: "GBPSEK", display: "GBPSEK" },
    { name: "GBPTRY", display: "GBPTRY" },
    { name: "NZDCAD", display: "NZDCAD" },
    { name: "NZDCHF", display: "NZDCHF" },
    { name: "NZDJPY", display: "NZDJPY" },
    { name: "NZDSGD", display: "NZDSGD" },
    { name: "USDCNH", display: "USDCNH" },
    { name: "USDZCK", display: "USDZCK" },
    { name: "USDDKK", display: "USDDKK" },
    { name: "USDHKD", display: "USDHKD" },
    { name: "USDHUF", display: "USDHUF" },
    { name: "USDMXN", display: "USDMXN" },
    { name: "USDNOK", display: "USDNOK" },
    { name: "USDPLN", display: "USDPLN" },
    { name: "USDRUB", display: "USDRUB" },
    { name: "USDSEK", display: "USDSEK" },
    { name: "USDSGD", display: "USDSGD" },
    { name: "USDTRY", display: "USDTRY" },
    { name: "USDZAR", display: "USDZAR" },
    { name: "UKOUSD", display: "UKOUSD" },
    { name: "USOUSD", display: "USOUSD" },
    { name: "XAGEUR", display: "XAGEUR" },
    { name: "XAGUSD", display: "XAGUSD" },
    { name: "XAUEUR", display: "XAUEUR" },
    { name: "XAUUSD", display: "XAUUSD" },
    { name: "XNGUSD", display: "XNGUSD" },
    { name: "XPDUSD", display: "XPDUSD" },
    { name: "XPTUSD", display: "XPTUSD" },
    { name: "EURAUD", display: "EURAUD" },
    { name: "USDCNY", display: "USDCNY" },
    { name: "EURSGD", display: "EURSGD" },
    { name: "USDTHB", display: "USDTHB" },
    { name: "GBPSGD", display: "GBPSGD" },
    { name: "CADSGD", display: "CADSGD" },
    { name: "AUDSGD", display: "AUDSGD" },
    { name: "SGDJPY", display: "SGDJPY" },
    { name: "XAUGBP", display: "XAUGBP" },
    { name: "USDCNH", display: "USDCNH" },
  ];
  const load = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const onSearch = (event) => {
    if (event.target.value !== "") {
      var updatedList = filteredData;
      updatedList = updatedList.filter((item) => {
        return (
          item.toLowerCase().search(event.target.value.toLowerCase()) !== -1
        );
      });
      setResault(updatedList);
    } else {
      setResault([]);
    }
  };

  const setFillteredSymbol = (el) => {
    setSearchResault(el);
  };

  useEffect(() => {
    const pusher = new Pusher("44f2900f2b03ee38357a", {
      cluster: "ap1",
      appId: 1158859,
      encrypted: true,
    });
    const channel = pusher.subscribe("quotes");

    channel.bind("update", function (e) {
      if (e.length > 10) {
        const isFilter = [];
        const filteredMarketStock2 = e.forEach((data) => {
          const matchingStock = marketALL.find(
            (stock) => stock.name.toLowerCase() === data.s.toLowerCase()
          );

          if (matchingStock) {
            data.display = matchingStock.display;
          }
        });

        const a = e.filter((ab) => currency.includes(ab.s));
        const b = e.filter((bc) => cryptoCurrency.includes(bc.s));
        const c = e.filter((cd) => indexs.includes(cd.s));
        const d = e.filter((de) => marketStock.includes(de.s));
        const f = e.filter((fg) => searchResault.includes(fg.s));

        setData(e);
        setCurrencies(a);
        setCrrypto(b);
        setMarketIndex(c);
        setStocks(d);
        setSearch(f);
        isFilter.push(e.map((s) => s.s));
        setFilteredData(isFilter[0]);
      }
    });
    const activeCover = () => {
      if (pathName) {
        menu.filter((e, i) => e.link === pathName && setIndex(e.id));
      }
    };
    activeCover();
  }, [pathName]);

  const lang = localStorage.getItem("locale");
  return (
    <div>
      <div
        className="container"
        // style={{ paddingTop: window.innerWidth <= 425 ? "20%" : "5%" }}
      >
        <TopMenu>
          {" "}
          <div className="d-flex justify-content-between w-100 p-4 align-items-center">
            <h6 className=" font-bold-iux text-left fs-32 text-uppercase">
              {locale.t("market")}
            </h6>
          </div>
          <div className="sub-menu-page-user" key={2}>
            <div className="container">
              <div className="d-flex overflow-auto scroll-menu-hide">
                {menu.map((e, i) => (
                  <Link to={e.link}>
                    <MenuItem
                      onClick={() => setIndex(i)}
                      color={e.link === pathName ? "#000" : "#8D8D8D"}
                      borderBottom={e.link === pathName}
                      fontWeight={e.link === pathName ? 700 : "unset"}
                    >
                      {e.text}
                    </MenuItem>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </TopMenu>
      </div>
      <div className="container mt-4">
        <div className="row">
          {data === null ? (
            load.map((e) => (
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div
                  className={`w-100 ${"bg-spreads-box-dashboard"} border-round-16 mb-2 p-0`}
                >
                  <div className="p-3 p-lg-2 p-md-2 box-market-landingpage">
                    <div
                      className="d-flex justify-content-between"
                      style={styleHeaderDashboard}
                    >
                      <div>Loading ...</div>
                    </div>

                    <div
                      className="d-flex justify-content-between pl-3 pr-3"
                      // style={styleBackground}
                    >
                      <div className="w-100">
                        <div style={styleBitAsk}>Bid</div>
                        0.0
                      </div>

                      <div className="w-100">
                        <div style={styleBitAsk} className="ml-2">
                          Ask
                        </div>
                        0.0
                      </div>
                    </div>

                    <div
                      className="d-flex justify-content-between"
                      // style={styleBottom}
                    >
                      <div className="w-100">
                        <div
                          className=" d-flex justify-content-between mr-1 btn-outline-iux"
                          style={styleSpreadDashboard}
                        >
                          Spread 0.0
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="d-flex justify-content-between ml-1 ">
                          <>
                            <div className="" />
                            <Link
                              to={`/${localStorage.getItem("locale")}/login`}
                            >
                              <button
                                style={styleBuyDashboard}
                                className="btn-primary-new-iux"
                              >
                                Buy
                              </button>
                            </Link>
                            <Link
                              to={`/${localStorage.getItem("locale")}/login`}
                            >
                              <div
                                className="btn-outline-iux"
                                style={styleSellDashboard}
                              >
                                Sell
                              </div>
                            </Link>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Switch>
              <Route
                path={`/:lang/wallet/spread/search`}
                render={() => {
                  return <AllSymbol data={search} islayout="dashboard" />;
                }}
              />
              <Route
                path={`/:lang/wallet/spread/stocks`}
                render={() => {
                  return <AllSymbol data={stocks} islayout="dashboard" />;
                }}
              />
              <Route
                path={`/:lang/wallet/spread/index`}
                render={() => {
                  return <AllSymbol data={marketIndex} islayout="dashboard" />;
                }}
              />
              <Route
                path={`/:lang/wallet/spread/crypto`}
                render={() => {
                  return <AllSymbol data={crypto} islayout="dashboard" />;
                }}
              />
              <Route
                path={`/:lang/wallet/spread/currency`}
                render={() => {
                  return <AllSymbol data={currencies} islayout="dashboard" />;
                }}
              />
              <Route
                path={`/:lang/wallet/spread`}
                render={() => {
                  return <AllSymbol data={data} islayout="dashboard" />;
                }}
              />
            </Switch>
          )}
        </div>
      </div>
    </div>
  );
};

export default Symbols;
