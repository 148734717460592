export const CheckPhoneCode = (id) => {
  switch (id) {
    case 1:
      return { code: "+66" };
    case 2:
      return { code: "+44" };
    case 3:
      return { code: "+1" };
    case 4:
      return { code: "+86" };
    case 5:
      return { code: "+65" };
    case 6:
      return { code: "+60" };
    case 7:
      return { code: "+91" };
    case 8:
      return { code: "+856" };
    case 9:
      return { code: "+62" };
    case 10:
      return { code: "+84" };
    case 11:
      return { code: "+95" };
    case 12:
      return { code: "+63" };
    case 13:
      return { code: "+81" };
    default:
      return { code: "" };
  }
};
